<template>
  <div style="margin-top: 10%;">
    <h1 class="text-center">{{ $t('workspace.add_name.h1_1') }} <br> {{ $t('workspace.add_name.h1_2') }}</h1>
    <card class="m-auto workspace workspace__auth-card">
      <div class="p-4 workspace__auth_card">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(goOn)">
            <ValidationProvider>
              <base-input class="mb-0" :label="$t('workspace.name')" type="text"
                :placeholder="$t('placeholder.companyClientProject')" v-model="form.workSpaceName">
              </base-input>
            </ValidationProvider>
            <base-button native-type="submit" type="primary"
              :class="['btn-fill mt-3 py-3 px-3 w-100 btn_submit_form', !form.workSpaceName ? 'disabled' : '']">
              {{ $t('workspace.add_name.create') }}
            </base-button>
            <base-button @click.prevent="goBack" type="secondary" class="btn-fill mt-2 py-3 px-3 w-100 btn_add_link">
              {{ $t('workspace.cancel') }}
            </base-button>
          </form>
        </ValidationObserver>
      </div>
    </card>
  </div>
</template>
<script>
export default {
  props: {
    workSpaceCreationStep: {
      type: Number,
    },
  },
  components: {
  },
  data() {
    return {
      form: {
        workSpaceName: ''
      },
    };
  },
  computed: {

  },
  methods: {
    goOn() {
      this.$emit('changeStep', 2, this.form.workSpaceName);
    },
    goBack() {
      this.form.workSpaceName = ''
      this.$router.push({ name: 'workspace.login' });
    }
  },
  created() {
  }
};
</script>